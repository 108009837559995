
@media screen and (max-width: 440px) {

    .editor-tab-menu .nav-item:nth-child(n+4) {
        margin-top: 20px;
    }

    .editor-tab-menu .nav-item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .style-part-sec .style-box-wrapper .colmn.col-md-8,
    .style-part-sec .style-box-wrapper .colmn:last-child {
        max-width: 100%!important;
    }

    .save-render-sec .save-projectbtn, .save-render-sec .render-projectbtn {
        flex: 0 0 50%;
        max-width: 45%!important;
    }

    .start-end-time input {
        font-size: 10px!important;
        /* width: 50px!important; */
    }

    /* .cb-col.cb-col-1 {
        width: 100%!important;
        padding: 0px 10px!important;
    }

    .cb-col.cb-col-2 {
        width: 70%!important;
    } */

    .play-push-left-right img, .expand-button img {
        width: 12px!important;
    }

}

@media screen and (max-width: 575px) {
    .audio-box-sec .audio-box-menu .tab-pane .row .colmn {
        max-width: 50%!important;
    }

    .banner-content {
        padding: 40px 0px;
    }
    .banner-content h1 {
        font-size: 50px;
    }

    .project-header-name .project-item h4 {
        font-size: 20px;

    }
    .project-header-name .project-conut h6{
        font-size: 20px;
    }

    .banner-content .watch-demo-btn button{
        padding: 15px 50px;
    }
    .banner-video-project .video-project a span {

        margin-left: 0 !important;
    }



    .banner-video-project .video-project a{
        font-size: 14px;
    }

    .banner-video-project .video-project h5 {
        font-size: 20px;

    }



    .banner-content p {
        width: 100%;
    }
    .banner-items{
        padding: 40px 0px;
    }

    .dashborad-sec-3{
        padding: 40px 0px;
    }

    .imgsizeleft{
        margin-top: 30px;
    }

    .dashborad-sec-3-content {
        margin-top: 30px;
    }
    header .dropdown-menu {
        left: 0 !important;
    }

    .video-card{
        margin-top: 30px;
    }

    .login-box{
        padding: 40px 0px;
    }

    .login-form{
        padding: 20px;
        margin-top: 20px;
    }
    .login-form p{
        font-size: 14px;
    }

    .login-form h4 {
        font-size: 17px;
    }
    .login-content h5 {
        font-size: 20px;
    }

    .project-button{
        flex-direction: column !important;
        margin-top: 20px;
        justify-content: center;
    }

    .project-button .project-create-btn{
        margin-top: 20px;
    }

    .project-input-box{
        margin-right: 0px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }


    .search-select{
        flex-direction: column;
        justify-content: start;
    }

    .search-select .search-box{
        margin-top: 20px;
    }

    .template-name h4{
        font-size: 14px;
    }

    .template-number h6{
        font-size: 14px;
    }

    .select-box {
        margin-top: 15px;
    }
}

@media screen and (max-width: 640.98px) {
    .editor-preview-wrapper {
        width: 95%!important;
    }

    .save-render-sec .save-projectbtn, .save-render-sec .render-projectbtn {
        flex: 0 0 50%;
        max-width: 30%;
        margin: 0!important;
        text-align: center;
    }

    .save-render-sec .add-campaign {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0!important;
    }

    .save-render-sec {
        max-width: 100%;
        margin-left: unset;
    }

    .save-render-sec .add-campaign .form-control {
        width: 100%;
        margin-bottom: 10px;
    }



    .start-end-time input {
        font-size: 12px;
        /* width: 70px; */
    }

    .ar-9-16 {
        max-width: 40%;
        max-height: 100%;
    }

    .editor-preview-wrapper {
        max-width: 100%;
    }

    .play-push-left-right ul img, .expand-button img {
        max-width: 2vw;
    }

    /* .cb-col-1 {
        width: 30%;
    } */

    .play-push-left-right img,
    .expand-button img {
        width: 15px;
    }
}

@media screen and (max-width: 767px) {

    .editor-part .tab-pane {
        width: 90%!important;
    }

    .editor-part .tab-pane .tab-pane {
        width: 100%!important;
    }

    .audio-box-menu .tab-pane .row .colmn {
        max-width: 33.33%!important;
    }

    .style-box-wrapper .colmn:last-child {
        max-width: 33%!important;
    }

    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

}

@media screen and (max-width: 991.98px) {
    .ep-as-r-9-16 .editor-preview-wrapper .editor-preview-box {
        transform-origin: unset!important;
        transform: scale(1)!important;
    }

    .editor-sec .editor-container .colmn {
        max-width: 100%!important;
    }

    .colmn-preview-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* Header Start */
    header .navbar .navbar-nav .active::before {
        width: 50px;
        left: 0;
        top: 40px;
        margin: unset;
    }

    .nav-link {
        padding: 15px 0;
    }

    /* Editor Tab box */
    /* .editor-preview .editor-preview-wrapper {
        width: 90vw!important;
    }
     */
    .editor-tab-box {
        order: 3;
    }

    .editor-part .tab-pane {
        width: 60%;
        margin: 0 auto;
    }

    .editor-part .tab-pane .tab-pane {
        width: 100%;
    }

    .style-box-wrapper .colmn.col-md-8 {
        max-width: 63%!important;
    }

    .style-box-wrapper .colmn {
        flex: unset!important;
        height: unset!important;
    }

    .editor-main {
        display: unset;
        flex-direction: unset;
        justify-content: unset;
        height: unset;
        width: unset;
    }

    .imgsizeleft{
        margin-top: 30px !important;
    }

    .dashborad-sec-3-content {
        margin-top: 30px !important;
    }

    .login-form{
        margin-top: 30px;
    }

    .video-card{
        margin-top: 30px;
    }

    .footer .col-lg-4{
        display: flex;
        justify-content: center !important;
    }

    .project-button {
        margin-top: 30px;
    }

    .banner-items{
        padding: 60px 0px;
    }
    .banner-content{
        padding: 60px 0px;
    }
    .dashborad-sec-3{
        padding: 30px 0px;
    }

    .position-text-box {
        min-width: 123px !important;
        max-width: 123px !important;
        margin: auto;
    }
}

@media screen and (max-width: 1024.98px) {
    .controls-box .col-4 {
        padding: 0;
    }

    .play-push-left-right ul {
        width: 80%;
    }

    .start-end-time input {
        font-size: 9px;
    }
}

@media screen and (max-width: 1199.98px) {
    /* .editor-preview-wrapper {
        width: 48vw!important;
    } */

    .login-content h1{
        font-size: 50px;
    }

    .template-name h4{
        font-size: 18px;
    }

    .position-text-box {
        min-width: 123px;
        max-width: 123px;
    }

}

@media screen and (max-width:1300px){
    .position-text-box{
        min-width: 122px;
        max-width: 122px;
    }
}


@media screen and (max-width: 1440.98px) {
    /* .editor-preview-wrapper {
        width: 36vw!important;
    } */
}

@media screen and (min-width:1400px){
    .save-render-sec{
        width: 100%;
    }

    .render-projectbtn {
        margin-right: 0px;
    }

    .editor-tab-menu{
        width: 100%;
    }

}

@media screen and (max-width:1700px){
    /* Style Logo Tab */
    .style-box-wrapper .colmn {
        height: 155px!important;
    }
}


@media screen and (max-width:1500.98px){
    .editor-sec .editor-container .colmn-1 {
        max-width: 28%;
    }

    .editor-sec .editor-container .colmn-2 {
        max-width: 72%;
    }

    /* Audio Box */
    .audio-box-menu .tab-pane .row .colmn {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 10px;
    }

    .tts-box-wrapper .form-group select {
        font-size: 12px;
    }

    .tts-box-wrapper .row .col-md-4 {
        padding: 0 5px;
    }

    .tts-box-wrapper .row:last-child .col-md-6:first-child {
        margin-bottom: 10px;
    }

    .tts-box-wrapper .row:last-child .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
    }


    /* Text Editor */
    .editor-font-color .swatch, .editor-font-color .no-color {
        flex: 0 0 11%;
        max-width: 10.8%;
    }

    .editor-font-color .input-color {
        max-width: 40%;
    }

    .editor-font-color .editor-rgb-wrapper {
        flex: 0 0 17%;
        max-width: 17%;
    }

    .editor-font-color .editor-rgb-wrapper input {
        font-size: 12px;
        text-align: center;
    }

    /* Media Tab */
    .media-menu-box .nav-item .nav-link,
    .audio-box-sec .audio-box-menu .nav-item .nav-link,
    .audio-box-sec .audio-box-menu .nav-item .nav-link.active {
        font-size: 10px;
    }

    /* Style Editor */
    .style-box-wrapper .colmn {
        flex: 0 0 100%;
        max-width: 100%!important;
        height: 100%!important;
    }

    .style-box-wrapper .colmn.col-md-8 {
        margin-bottom: 15px;
    }

    .position-text-box {
        width: 50%;
        margin: 0 auto;
    }


}

@media screen and (max-width:1366.98px){
    .ep-as-r-9-16 .editor-preview-wrapper .editor-preview-box {
        transform-origin: 50% 0%;
        transform: scale(1.1);
    }

    #pills-style, #pills-credits {
        padding-bottom: 60px;
    }

    .editor-preview {
        padding: 1.5vh 2vw;
    }

    .ar-1-1 {
        max-width: 55%;
        max-height: 90.1%;
    }

    .search-load-box .row {
        max-height: 68vh;
        display: flex;
        justify-content: space-around;
    }

    .scroll-wrapper > .row {
        height: 64vh;
    }

    .upload-audio-box > .row {
        height: 360px;
    }

    /* Header */
    /* header .navbar ul li a {
        font-size: 12px;
    } */

    header .navbar {
        padding: 10px 40px!important;
    }

    /* Text Editor */
    .credites-upload-box label {
        font-size: 12px;
    }

    .editor-tab-menu .nav-item .nav-link .img-wrapper {
        width: 20px;
        height: 24px;
    }

    .editor-tab-menu .nav-item .nav-link h6 {
        font-size: 10px;
    }

    .editor-preview-wrapper {
        width: 65%;
    }

    .play-push-left-right ul img,
    .expand-button ul img {
        width: 15px;
    }

    .editor-sec .editor-container .colmn-1 {
        max-width: 32%;
    }

    .editor-sec .editor-container .colmn-2 {
        max-width: 68%;
    }

    .editor-font-color .swatch, .editor-font-color .no-color {
        flex: 0 0 15%;
        max-width: 12%;
    }
}

@media screen and (min-width:1500px){
    .container-fluid{
        max-width: 1400px;
    }

    .render-projectbtn{
        margin-right: 0px !important;
    }
}

@media screen and (max-width: 1680.98px) {
    .ep-as-r-9-16 .editor-preview-box {
        transform-origin: 50% 80%;
    }
}

@media screen and (max-width: 1440.98px) {
    .ep-as-r-9-16 .editor-preview-box {
        transform-origin: 50% 70%;
        transform: scale(1.3);
    }
}
